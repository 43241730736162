angular.module('MyHippoCommons.Directives').directive('nyAnnouncementModal', function () {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: '/modals/ny-announcement/ny-announcement.html',
        controller: function ($window, $log, $scope, $mdDialog) {
            $log.info('NYAnnouncementModal Controller');

            // NY Announcement feature modal;
            $scope.dontShowAgainNYAnnouncementModal = false;
            $scope.shouldDisplayNYAnnouncementModal = !($window.localStorage.getItem('hasNYAnnouncementModalSeen') === 'true');
            $scope.closeNYAnnouncementModal = () => {
                $scope.shouldDisplayNYAnnouncementModal = false;
                $window.localStorage.setItem('hasNYAnnouncementModalSeen', $scope.dontShowAgainNYAnnouncementModal);
                $mdDialog.hide();
            };
            $scope.onDontShowNYAnnouncementModalAgainClicked = (value) => {
                $scope.dontShowAgainNYAnnouncementModal = (value === true);
            };

        },
    };
});
